var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';
// ----------------------------------------------------------------------
// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...
// ----------------------------------------------------------------------
var Types;
(function (Types) {
    Types["INITIAL"] = "INITIAL";
    Types["LOGIN"] = "LOGIN";
    Types["REGISTER"] = "REGISTER";
    Types["LOGOUT"] = "LOGOUT";
})(Types || (Types = {}));
// ----------------------------------------------------------------------
var initialState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
};
var reducer = function (state, action) {
    if (action.type === Types.INITIAL) {
        return {
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
        };
    }
    if (action.type === Types.LOGIN) {
        return __assign(__assign({}, state), { isAuthenticated: true, user: action.payload.user });
    }
    if (action.type === Types.REGISTER) {
        return __assign(__assign({}, state), { isAuthenticated: true, user: action.payload.user });
    }
    if (action.type === Types.LOGOUT) {
        return __assign(__assign({}, state), { isAuthenticated: false, user: null });
    }
    return state;
};
// ----------------------------------------------------------------------
export var AuthContext = createContext(null);
export function AuthProvider(_a) {
    var _this = this;
    var children = _a.children;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var storageAvailable = localStorageAvailable();
    var initialize = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var accessToken, response, user, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
                    if (!(accessToken && isValidToken(accessToken))) return [3 /*break*/, 2];
                    setSession(accessToken);
                    return [4 /*yield*/, axios.get('/auth/me')];
                case 1:
                    response = _a.sent();
                    user = response.data.data;
                    dispatch({
                        type: Types.INITIAL,
                        payload: {
                            isAuthenticated: true,
                            user: user,
                        },
                    });
                    return [3 /*break*/, 3];
                case 2:
                    dispatch({
                        type: Types.INITIAL,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    console.error(error_1);
                    dispatch({
                        type: Types.INITIAL,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [storageAvailable]);
    useEffect(function () {
        initialize();
    }, [initialize]);
    // LOGIN
    var login = useCallback(function (email, password, ip) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, token, user;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axios.post('/auth/login-takiacademy', {
                        credential: email,
                        password: password,
                        ipAddress: ip,
                    })];
                case 1:
                    response = _b.sent();
                    _a = response.data.data, token = _a.token, user = _a.user;
                    setSession(token);
                    dispatch({
                        type: Types.LOGIN,
                        payload: {
                            user: user,
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); }, []);
    // REGISTER
    var register = useCallback(function (email, password, firstName, lastName) { return __awaiter(_this, void 0, void 0, function () {
        var response, _a, accessToken, user;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axios.post('/auth/register', {
                        email: email,
                        password: password,
                        firstName: firstName,
                        lastName: lastName,
                    })];
                case 1:
                    response = _b.sent();
                    _a = response.data, accessToken = _a.accessToken, user = _a.user;
                    localStorage.setItem('accessToken', accessToken);
                    dispatch({
                        type: Types.REGISTER,
                        payload: {
                            user: user,
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); }, []);
    // LOGOUT
    var logout = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    axios
                        .delete('/auth/logout')
                        .then(function (res) {
                        setSession(null);
                        dispatch({
                            type: Types.LOGOUT,
                        });
                        return resolve({ data: res.data });
                    })
                        .catch(function (err) {
                        reject(err);
                    });
                })];
        });
    }); }, []);
    var logoutUser = useCallback(function (userId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    axios
                        .delete("/auth/logout/".concat(userId))
                        .then(function (res) { return resolve({ data: res.data }); })
                        .catch(function (err) { return reject(err); });
                })];
        });
    }); }, []);
    var memoizedValue = useMemo(function () { return ({
        isInitialized: state.isInitialized,
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        method: 'jwt',
        login: login,
        loginWithGoogle: function () { },
        loginWithGithub: function () { },
        loginWithTwitter: function () { },
        register: register,
        logout: logout,
        logoutUser: logoutUser,
    }); }, [state.isAuthenticated, state.isInitialized, state.user, login, logout, register, logoutUser]);
    return _jsx(AuthContext.Provider, __assign({ value: memoizedValue }, { children: children }));
}
